<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">财务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/finance/purchase/expenses-requisition' }"
          >请款审核</el-breadcrumb-item
        >
        <el-breadcrumb-item>查看请款单</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <h2>采购请款单</h2>
    <el-row>
      <el-form>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="单号">
              &nbsp;&nbsp;{{ order.id }}
              <el-tag size="mini">{{ order.step }}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="供应商">
              &nbsp;&nbsp;{{ order.vendor_name }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="金额">
              &nbsp;&nbsp;{{ order.amount }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="付款期限">
              &nbsp;&nbsp;{{ order.deadline }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="收款单位">
              &nbsp;&nbsp;<span>{{ order.bank_name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开户行">
              &nbsp;&nbsp;{{ order.bank }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="账号">
              &nbsp;&nbsp;{{ order.bank_account }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16" style="margin:10px 0px 30px 0px">
          <el-button
            type="primary"
            size="small"
            v-if="order.step === '已完成'"
            @click="confirmOrder(1)"
            >反审核</el-button
          >
          <el-button
            type="primary"
            size="small"
            v-if="order.step === '待审核'"
            @click="confirmOrder(0)"
            >审核通过</el-button
          >
        </el-row>
      </el-form>
    </el-row>
    <div>
      <el-table stripe style="width:100%" :data="stockorderlist" border>
        <el-table-column prop="order_id" label="采购单">
          <template slot-scope="scope">
            {{ scope.row.order_id }}
            <el-button
              @click="$refs['purchase-detail'].show(scope.row.order_id)"
              type="text"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="_prepay_ratio" label="预付"></el-table-column>
        <el-table-column prop="total_amount" label="采购金额"></el-table-column>
        <el-table-column
          prop="remaind_amount"
          label="可请款金额"
        ></el-table-column>
        <el-table-column prop="amount" label="请款金额"></el-table-column>
        <el-table-column prop="_type" label="类型"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
    </div>
    <purchase-order-detail-dialog
      ref="purchase-detail"
    ></purchase-order-detail-dialog>
  </div>
</template>
<script>
import * as cashOutApi from '@/api/cashOut'
import purchaseOrderDetailDialog from './purchase-order-detail-dialog'
export default {
  components: {
    purchaseOrderDetailDialog
  },
  data () {
    return {
      order: {},
      stockorderlist: [],
      id: this.$route.params.id
    }
  },
  created () {
    console.log(this.$route)
    this.getprepaymentOrderDetail()
  },
  methods: {
    getprepaymentOrderDetail () {
      cashOutApi
        .getprepaymentOrderDetail({ id: this.id })
        .then(data => {
          this.order = data
          this.stockorderlist = data.detail
        })
        .catch(err => {
          console.log(err)
        })
    },
    confirmOrder (flag) {
      console.log(flag)
      const confirmformdata = new FormData()
      confirmformdata.append('id', this.order.id)
      confirmformdata.append('reverse', flag)
      if (flag === 0) {
        this
          .$confirm('提示', {
            title: '请款审核',
            message:
              '务必核对清楚必要的线下资料，审核通过后请款单将自动完成支付,确定审核通过?'
          })
          .then(_ => {
            cashOutApi
              .confirmprepaymentorder(confirmformdata)
              .then(data => {
                this.getprepaymentOrderDetail()
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(_ => {})
      } else {
        this
          .$confirm('提示', {
            title: '请款反审核',
            message:
              '反审核后请款单的支付记录也将被清除且不可撤销。 确定执行反审核?'
          })
          .then(_ => {
            cashOutApi
              .confirmprepaymentorder(confirmformdata)
              .then(data => {
                this.getprepaymentOrderDetail()
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(_ => {})
      }
    }
  }
}
</script>
