<template>
  <el-dialog :visible.sync="visible" title="采购单详情" width="1280px">
    <h3>订单信息</h3>
    <el-row>
      <el-form size="small">
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="单号">
              &nbsp;&nbsp;{{ orderdata.id }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="合同号">
              &nbsp;&nbsp;{{ orderdata.contract_id }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="6" :offset="2">
            <el-form-item label="流程">
              &nbsp;&nbsp;{{ orderdata._step }}
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="2">
            <el-form-item label="采购员">
              &nbsp;&nbsp;{{ orderdata.user && orderdata.user.name }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建时间">
              &nbsp;&nbsp;{{ orderdata.created_at }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="6" :offset="2">
            <el-form-item label="交期">
              &nbsp;&nbsp;{{ orderdata.ship_date }}
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="2">
            <el-form-item label="目的仓库">
              &nbsp;&nbsp;{{ orderdata.warehouse_id }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="运输方式">
              &nbsp;&nbsp;{{ orderdata._transport_type }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="6" :offset="2">
            <el-form-item label="开票类型">
              &nbsp;&nbsp;{{ orderdata._invoice_type }}
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="2">
            <el-form-item label="币种">
              &nbsp;&nbsp;{{ orderdata._currency }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="预付定金">
              &nbsp;&nbsp;{{ orderdata._prepay_ratio }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="10">
            <el-form-item label="备注">
              &nbsp;&nbsp;{{ orderdata.remark }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row>
      <h3>采购明细</h3>
    </el-row>
    <el-row>
      <el-table stripe style="width:100%" :data="ordetailList" border>
        <el-table-column>
          <template slot-scope="scope">
            <el-popover
              ref="popover"
              placement="right-start"
              trigger="hover"
              :visible-arrow="false"
            >
              <img
                v-if="scope.row.product.cover"
                :src="scope.row.product.cover"
                style="width: 200px; height: 200px;"
              />
              <img
                v-else
                src="/static/no-photo.jpg"
                style="width: 200px; height: 200px;"
              />
              <img
                slot="reference"
                v-if="scope.row.product.cover"
                :src="scope.row.product.cover"
                style="width: 60px;height: 60px;border: 1px solid #F9FAFC;vertical-align: middle; margin: 5px"
              />
              <img
                slot="reference"
                v-else
                src="/static/no-photo.jpg"
                style="width: 60px;height: 60px;border: 1px solid #F9FAFC;vertical-align: middle; margin: 5px"
              />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="品名编号"
          width="160"
          key="2"
        >
          <template slot-scope="scope">
            {{ scope.row.product.mfn }}
            <br />
            {{ scope.row.product && scope.row.product.name }}
          </template>
        </el-table-column>
        <el-table-column prop="sku,vendor_sku" label="SKU/供应商编号">
          <template slot-scope="scope">
            {{ scope.row.sku }}/{{ scope.row.vendor_sku }}
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="采购总数"></el-table-column>
        <el-table-column prop="sellpack" label="套系"></el-table-column>
        <el-table-column prop="price" label="单价（含税）"></el-table-column>
        <el-table-column prop="amount" label="总金额"></el-table-column>
      </el-table>
    </el-row>
  </el-dialog>
</template>
<script>
import * as purchaseOrderApi from '@/api/stockOrder'
export default {
  data () {
    return {
      visible: false,
      orderid: '',
      orderdata: {},
      ordetailList: []
    }
  },
  methods: {
    show (id) {
      this.orderid = id
      this.getOrderdata()
      this.getOrderdetailList()
      this.visible = true
    },
    getOrderdata () {
      purchaseOrderApi
        .getStockDetail({ id: this.orderid })
        .then(data => {
          this.orderdata = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getOrderdetailList () {
      purchaseOrderApi
        .getStockDetailItem({ order_id: this.orderid })
        .then(data => {
          this.ordetailList = data
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
