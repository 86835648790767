import request from '../config/request'

//  请款单列表
export const getprepaymentOrder = data => {
  return request('finance/purchase/prepayment/order', 'get', data, true).then(
    data => {
      return data
    }
  )
}
//  请款单明细
export const getprepaymentOrderDetail = data => {
  return request('finance/purchase/prepayment/order/detail', 'get', data).then(
    data => {
      return data
    }
  )
}
//  审核反审核请款单
export const confirmprepaymentorder = data => {
  return request(
    'finance/purchase/prepayment/order/detail/confirm',
    'post',
    data
  ).then(data => {
    return data
  })
}
